import {
  SitemapI,
  NodeLink,
  ExtractedData,
  LinksByCountry,
} from "./SitemapI.type";
import * as styles from "./Sitemap.module.scss";

const SiteMap = ({
  howItWorks,
  data: { allContentfulCountryRequirements },
}: SitemapI) => {
  function extractCountryLinks(data: Array<NodeLink>): ExtractedData {
    const countries: string[] = [];
    const links: LinksByCountry = {};

    for (let i = 0; i < data.length; i++) {
      const slug = data[i].node.slug;
      const serviceName = data[i].node.serviceName;

      // Extract the country name from the slug
      const countryMatch = slug.split("/")[0];
      if (countryMatch && countryMatch != "dummy-content") {
        const country = countryMatch;
        if (!countries.includes(country)) {
          countries.push(country);
        }

        // Add the link to the country's list in the links object
        if (links[country]) {
          links[country].push({ slug, serviceName });
        } else {
          links[country] = [{ slug, serviceName }];
        }
      }
    }

    // Sort the country names alphabetically
    countries.sort();

    return { countries, links };
  }
  const countryLinks = extractCountryLinks(
    allContentfulCountryRequirements.edges
  );
  return (
    <div className={styles.sitemapWrapper}>
      <div className={styles.sitemapTopSec}>
        <div className={styles.sitemapColumn}>
          <h3>{howItWorks.label}</h3>
          <ul className={styles.mapLinks}>
            {howItWorks.links.map((subItem, index) => {
              return (
                <li key={`subitem_${index}`}>
                  <a href={subItem.url}>{subItem.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.sitemapColumnManual}>
          <div className={styles.topSecGridItem}>
            <h3>Via</h3>
            <ul className={styles.mapLinks}>
              <li>
                <a href="/about-us/">About Us</a>
              </li>
              <li>
                <a href="/job-board/">Careers</a>
              </li>
            </ul>
          </div>
          <div className={styles.topSecGridItem}>
            <h3>
              <a href="/partnerships/">Partners</a>
            </h3>
          </div>
          <div className={styles.topSecGridItem}>
            <h3>
              <a href="/blog/">Blog</a>
            </h3>
          </div>
        </div>
      </div>
      <div>
        <h3>
          <a href="/eor/">Countries</a>
        </h3>
        {
          <div className={styles.sitemapBottomSec}>
            <ul className={styles.sitemapColumn}>
              {countryLinks.countries
                .slice(0, Math.ceil(countryLinks.countries.length / 2))
                .map((country, index) => {
                  return (
                    <li key={`country_${index}`}>
                      <div
                        className={
                          country.length <= 3
                            ? styles.countryNameUpper
                            : styles.countryName
                        }
                      >
                        {country}
                      </div>
                      <ul className={styles.countryLinks}>
                        {countryLinks.links[country].map((link, index) => {
                          return (
                            <li key={`country_${index}`}>
                              <a href={`/eor/${link.slug}`}>
                                {link.serviceName}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
            <ul className={styles.sitemapCountryColumn}>
              {countryLinks.countries
                .slice(Math.ceil(countryLinks.countries.length / 2))
                .map((country, index) => {
                  return (
                    <li key={`country_${index}`}>
                      <div
                        className={
                          country.length <= 3
                            ? styles.countryNameUpper
                            : styles.countryName
                        }
                      >
                        {country}
                      </div>
                      <ul className={styles.countryLinks}>
                        {countryLinks.links[country].map((link, index) => {
                          return (
                            <li key={`country_${index}`}>
                              <a href={`/eor/${link.slug}`}>
                                {link.serviceName}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </div>
        }
      </div>
    </div>
  );
};

export default SiteMap;
