// extracted by mini-css-extract-plugin
export var xs = "375";
export var sm = "576";
export var md = "768";
export var lg = "992";
export var xl = "1200";
export var xxl = "1440";
export var fhd = "1921";
export var sitemapWrapper = "Sitemap-module--sitemapWrapper--f5ZXv";
export var sitemapTopSec = "Sitemap-module--sitemapTopSec--2AVww";
export var topSecGridItem = "Sitemap-module--topSecGridItem--2aNRz";
export var sitemapBottomSec = "Sitemap-module--sitemapBottomSec--1J8_F";
export var countryName = "Sitemap-module--countryName--1ztvV";
export var countryNameUpper = "Sitemap-module--countryNameUpper--1cVym";
export var countryLinks = "Sitemap-module--countryLinks--1pn_R";
export var sitemapColumnManual = "Sitemap-module--sitemapColumnManual--1EdNW";