import { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import { useCookie } from "../utils/getCookie";
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import SiteMap from "../components/SitemapPage/Sitemap";

const CountryPage = ({ data, pageContext }: any) => {
  const dataSEO = {
    metaTitle: "Via Sitemap",
    metaDescription: "Via Sitemap Page",
    keywords: "",
    preventIndexing: false,
    alternateHrefLink: true,
    rating: true,
  };

  const { title, header_background, button, page_background } =
    pageContext.data;
  const {
    footer_menu,
    header_menu,
    footer,
    logo,
    cookieBarDescription,
    cookiePolicyLink,
    cookieBarTitle,
  } = pageContext.globalSettings;

  const [showCookiesConsent, setShowCookiesConsent] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const trackCookie = useCookie("gatsby-gdpr-tracking");
    const tagCookie = useCookie("via-gdpr-google-tagmanager");
    if (!trackCookie || !tagCookie) {
      setShowCookiesConsent(true);
    }
  }, []);

  return (
    <div>
      <Layout
        title={title}
        button={button}
        footer_menu={footer_menu}
        header_menu={header_menu}
        footer={footer}
        logo={logo}
        background={true}
        page_background={page_background}
        dataSEO={dataSEO}
        countryPage={true}
        blog={false}
        url={pageContext.url}
        setModal={setShowModal}
      >
        <SiteMap howItWorks={header_menu[0]} data={pageContext.data} />
        {showCookiesConsent && (
          <CookiesBanner
            dismiss={() => setShowCookiesConsent(false)}
            cookieBarDescription={cookieBarDescription}
            cookiePolicyLink={cookiePolicyLink}
            cookieBarTitle={cookieBarTitle}
          />
        )}
        {showModal && (
          <RegisterForm modal={showModal} setModal={setShowModal} />
        )}
      </Layout>
    </div>
  );
};

export default CountryPage;
